// 引入 axios
import axios from "axios";



let base = 'https://www.hystagelight.com/portal_server';
// let base = 'http://127.0.0.1:8087/portal_server';
//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}
